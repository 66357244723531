import React, { useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import axios from "axios";
import useSettings from "../hooks/useSettings";
import { MdCancel } from "react-icons/md";
export default function GooglePlaceComplete({
  width = "full",
  error = "",
  type = "text",
  required = true,
  icon = "",
  hint = "",
  // onFocus,
  iconSelect = () => {},
  change = () => {},
  clearText = false,
  placeholder = "",
  className = "",
  padding = true,
  margin = 0,
  bounds = false,
  defaultValue = "",
  isAutoFetchAddress = false,
  currentLocationValue = {},
  placeSelectValue = {},
  currentPlace_id,
  onSetCurrentPick,
  pickup,
  addressOneTime = false,
  onClearValue,
  ...rest
}) {
  const { settings, fetchSettings } = useSettings();

  const direction = document
    .getElementsByTagName("html")[0]
    .getAttribute("dir");

  const [address, setAddress] = React.useState(defaultValue);
  const [pickupRideSelect, setPickupRideSelect] = React.useState();
  const [googleLoaded, setGoogleLoaded] = React.useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [block, setblock] = useState(null);
  const [currentLocation, setCurrentLocation] = React.useState(null);
  const [currentLocation_2, setCurrentLocation_2] = React.useState(null);
  const [WebApi, setWebApi] = useState(null);

  // const [currentLocationLat, setCurrentLocationLat] = React.useState(0);
  // const [currentLocationLng, setCurrentLocationLng] = React.useState(0);

  React.useEffect(() => {
    if (clearText === true) setAddress("");
  }, [clearText]);

  useEffect(() => {
    if (pickupRideSelect) {
      onSetCurrentPick(pickupRideSelect);
    }
  }, [pickupRideSelect]);
  // const fetchingSettings = async () => await fetchSettings();

  // alert(JSON.stringify(currentLocation));
  useEffect(() => {
    fetchSettings();

    setWebApi(JSON.parse(localStorage.getItem("web")));

    // if (currentLocationValue !== {}) {
    navigator?.geolocation?.getCurrentPosition(async (e) => {
      if (e) {
        if (addressOneTime === true) {
          setCurrentLocation_2({
            lat: e.coords.latitude,
            lng: e.coords.longitude,
          });
        }
        // setOnlyTimePopUp(false);
        // alert("jghyj");
      }
    });
    // }

    // fetchingSettings();
    // alert(JSON.stringify("settings"));
    // alert(JSON.stringify(settings));
    //   alert(JSON.stringify(currentLocationValue));
    //   alert(JSON.stringify(JSON.parse(localStorage.getItem("place_Id"))));
  }, []);

  const handleGetCurrentLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setLoadingLocation(true);
          const { latitude, longitude } = position.coords;

          setCurrentLocation_2({ lat: latitude, lng: longitude });

          try {
            // Make a request to the Google Maps Geocoding API to get place details
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${settings?.mapApi?.web}`
            );

            if (!response.ok) {
              throw new Error("Failed to fetch place details");
            }

            const data = await response.json();
            if (data.results && data.results.length > 0) {
              const placeId = data.results[0].place_id;
              const resultData = {
                results: [
                  {
                    address_components: data.results[0].address_components,
                    formatted_address: data.results[0].formatted_address,
                    geometry: data.results[0].geometry,
                    place_id: data.results[0].place_id,
                    types: data.results[0].types,
                  },
                ],
                status: data.status,
                selectedAddress: `${
                  data.results[0]?.address_components[1]?.short_name || ""
                }, ${data.results[0]?.address_components[2]?.short_name || ""}`,
                placeId: placeId,
              };
              setAddress(resultData?.results[0]?.formatted_address);
              setPickupRideSelect(resultData);
              // setRideDeatilsData({
              //   pickUp: resultData,
              // });

              // Now you can use or save the resultData
              //////console.log(resultData);
              //////console.log(address)
              setblock(null);
            }
          } catch (error) {
            console.error("Error fetching current location:", error);
            setLoadingLocation(false);
          }
        },
        (error) => {
          console.error("Error getting current position:", error);
          setLoadingLocation(false);
          setblock("text-[#DC2000]");
        }
      );
    } else {
      console.error("Geolocation not supported in this browser");
      setLoadingLocation(false);
    }
  };

  const placeSelect = async (e, placeId, suggestion, setcurrentAddress) => {
    // console.log(suggestion);
    // console.log(setcurrentAddress);
    // console.log(e);
    // console.log(placeId);

    // if (placeId) {
    const selectedPlaceId = suggestion?.place_id || suggestion?.placeId;
    if (selectedPlaceId) {
      if (setcurrentAddress) {
        setAddress(suggestion.formatted_address);
      } else {
        setAddress(
          suggestion.formattedSuggestion.mainText +
            "," +
            suggestion.formattedSuggestion.secondaryText ||
            suggestion.description
        );
      }
      // alert(JSON.stringify(currentLocationValue + " indi"));
      // alert(JSON.stringify(selectedPlaceId));
      // alert(JSON.stringify(settings?.mapApi?.web));
      // alert(JSON.stringify("apiKey"));
      // alert(JSON.stringify(apiKey));
      // alert(JSON.parse(localStorage.getItem("web")));
      if (currentLocationValue && selectedPlaceId && settings?.mapApi?.web) {
        const { status, data } = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?place_id=${selectedPlaceId}&key=${
            settings?.mapApi?.web || JSON.parse(localStorage.getItem("web"))
          }`
        );
        localStorage.setItem(
          "place_Id",
          JSON.stringify({ place_Id: selectedPlaceId })
        );
        if (status === 200) change({ ...data, selectedAddress: e, placeId });
      }
    }
  };

  //   React.useEffect(() => {
  //     navigator.geolocation.getCurrentPosition((e) => {
  //       if (e) {
  //         setCurrentLocation({ lat: e.coords.latitude, lng: e.coords.longitude });
  //         // alert(
  //         //   JSON.stringify({ lat: e.coords.latitude, lng: e.coords.longitude })
  //         // );
  //         // //   setAddress("marthandam");
  //         // alert(JSON.stringify(currentLocation + "___"));
  //       }
  //     });
  //   }, [currentLocation]);
  //     if (currentLocationValue) {
  //       //   setAddress(currentLocationValue);
  //       setCurrentLocation({
  //         lat: currentLocationValue.lat,
  //         lng: currentLocationValue.lng,
  //       });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataresponse = async () => {
    // alert(JSON.stringify(currentLocationValue + " Inside"));
    // alert(JSON.stringify(settings?.mapApi?.api));
    // // alert(JSON.stringify(currentLocationValue?.lat));
    // alert(JSON.stringify("apiKey"));
    // alert(JSON.stringify(apiKey));
    // alert(JSON.stringify(currentLocation_2));
    // alert(JSON.stringify("currentLocationValue?.lng"));
    if (
      currentLocationValue !== "" &&
      settings?.mapApi?.api &&
      currentLocationValue
    ) {
      // let lat = 0,
      //   lng = 0;
      // navigator.geolocation.getCurrentPosition((e) => {
      //   //   if (e) {
      //   //   lat = e.coords.latitude;
      //   //   lng = e.coords.longitude;
      //   setCurrentLocationLat(e.coords.latitude);
      //   setCurrentLocationLng(e.coords.longitude);
      //   // setCurrentLocation({ lat: e.coords.latitude, lng: e.coords.longitude });
      //   //   alert(JSON.stringify(lat, lng));
      //   // //   setAddress("marthandam");
      //   // alert(JSON.stringify(currentLocation + "___"));
      //   //   }
      // });

      const response = await axios(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          currentLocationValue.lat +
          // lat +
          // 8.305076 +
          // currentLocationLat +
          "," +
          currentLocationValue.lng +
          // lng +
          // 77.2260887 +
          // currentLocationLng +
          "&sensor=false" +
          "&key=" +
          settings?.mapApi?.api || JSON.parse(localStorage.getItem("web"))
      );
      // console.log(currentLocationValue);
      // console.log(response);
      // alert(JSON.stringify(response));

      if (response.status === 200) {
        const place_Id = response.data.results[0]?.place_id;
        // alert(JSON.stringify(response.data));
        // setAddress(response.data.plus_code.compound_code); //Initial stage address set
        localStorage.setItem(
          "place_Id",
          JSON.stringify({ place_Id: response.data.results[0]?.place_id })
        );
        const setcurrentAddress = true;
        // console.log(JSON.parse(localStorage.getItem("place_Id")))
        if (settings?.mapApi?.api) {
          placeSelect(
            response.data.results[0],
            place_Id,
            response.data.results[0],
            setcurrentAddress
          );
        }
      }
      //   change({
      //     ...response.data,
      //     selectedAddress: response.data.results[0].formatted_address,
      //     place_Id,
      //   });
      return response;
    }
    // return response;
  };
  // useEffect(() => {
  //   if (isAutoFetchAddress && settings?.mapApi?.api) {
  //     //   alert("isAutoFetchAddress");
  //     dataresponse();
  //   }
  //   // alert(JSON.stringify(dataresponse));
  //   //     }
  // }, [settings?.mapApi?.api]);

  useEffect(() => {
    if (isAutoFetchAddress && settings?.mapApi?.api) {
      dataresponse();
    }
  }, [settings?.mapApi?.api]);

  const handleFocus = (event) => {
    event.target.select();
  };
  return (
    <>
      {settings.mapApi.api ? (
        <div
          className={`relative   w-${width} ${
            document.getElementsByTagName("html")[0].getAttribute("dir") ===
            "ltr"
              ? `mr-${margin}`
              : `ml-${margin}`
          }`}
        >
          {/* {currentLocationValue ? ( */}
          {/* <>{console.log(address)}</> */}
          <PlacesAutocomplete
            debounce={800}
            value={address}
            onSelect={placeSelect}
            highlightFirstSuggestion={true}
            searchOptions={
              bounds
                ? {
                    bounds: {
                      north: bounds.lat + 0.1,
                      south: bounds.lat - 0.1,
                      east: bounds.lng + 0.1,
                      west: bounds.lng - 0.1,
                    },
                  }
                : currentLocation !== null
                ? {
                    bounds: {
                      north: currentLocation?.lat + 0.1,
                      south: currentLocation?.lat - 0.1,
                      east: currentLocation?.lng + 0.1,
                      west: currentLocation?.lng - 0.1,
                    },
                  }
                : null

              // (bounds = {
              //     lat: currentLocationValue.lat,
              //     lng: currentLocationValue.lng,
              //   }) // currentLocationValue !== null
              // ? {
              //     bounds: {
              //       north: currentLocationValue.lat + 0.1,
              //       south: currentLocationValue.lat - 0.1,
              //       east: currentLocationValue.lng + 0.1,
              //       west: currentLocationValue.lng - 0.1,
              //     },
              //   }
              //   false
            }
            shouldFetchSuggestions={address?.length > 0}
            onChange={(e) => {
              setAddress(e);
            }}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <div>
                {/* <input
              placeholder={placeholder}
              {...getInputProps({
                className:
                  className +
                  ` w-full text-sm focus:outline-none dark:focus:border-blue-800 bg-gray-100 rounded-xl px-4 py-3 border-b-2 border-gray-100 dark:border-gray-800  shadow shadow_blue ${
                    //focus:border-blue-800 dark:bg-gray-900
                    error !== "" && "border-2 shadow_red " //border-red-600
                  }`,
              })}
              {...rest}
            /> */}
                <input
                  placeholder={placeholder}
                  {...getInputProps({
                    className:
                      className +
                      ` p-2 pr-5 w-full truncate focus:outline-none bg-gray-200 ${
                        //focus:border-blue-800 dark:bg-gray-900
                        error !== "" && "border-2 shadow_red " //border-red-600
                      }`,
                  })}
                  {...rest}
                  onFocus={handleFocus}
                />

                <div
                  className="fixed mt-2 w-64 pr-2 flex flex-col z-20 rounded-lg max-h-44 shadow-xl overflow-y-scroll"
                  style={{ zIndex: 100 }}
                >
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "border-b-1 bg-gray-100 border-gray-200 cursor-pointer flex items-center bg-gray-50 px-2 py-2 text-sm hover:bg-gray-100" //dark:bg-gray-800
                      : "border-b-1  border-gray-200 cursor-pointer flex items-center bg-gray-50 px-2 py-2 text-sm"; //dark:bg-gray-900
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, { className })}
                      >
                        <span>
                          {suggestion.formattedSuggestion.mainText},
                          {suggestion.formattedSuggestion.secondaryText ||
                            suggestion.description}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          {/* ) : null} */}

          {address ? (
            <div
              className=" h-full absolute top-3 right-2  text-gray-100 px-1"
              onClick={() => {
                setAddress("");
                setLoadingLocation(false);
                onClearValue();
                // window.location.reload();
              }}
            >
              <div className="bg-gray-900 rounded-full">
                <MdCancel />
              </div>
            </div>
          ) : (
            !address &&
            pickup && (
              <div
                className={`  h-full absolute top-[10px] right-2  px-1 ${block}`}
                onMouseDown={() => {
                  handleGetCurrentLocation();
                }}
              >
                {!loadingLocation ? (
                  <svg
                    id="current-location-icon"
                    class="location-icon"
                    width="19"
                    height="19"
                    viewBox="0 0 24 24"
                    fill="none"
                    tabindex="0"
                    role="button"
                  >
                    <title>Navigate right up</title>
                    <path
                      d="M10.5 13.5.5 11 21 3l-8 20.5-2.5-10Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                ) : (
                  <div class="w-4 h-4 border-4 border-black border-t-transparent rounded-full animate-spin cursor-progress"></div>
                )}
              </div>
            )
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
